<script setup lang="ts">
import type { Icon } from "@kippie/ui";
import { ShippingMethodSidebar } from "#components";
import { SHIPPING_METHODS } from "~/lib/constants";
import { invalidzipcodearea } from "~/server/utils/invalidzipcodearea";

const shippingMethodSidebar = ref<InstanceType<typeof ShippingMethodSidebar>>();
const { selectedShippingMethod, shippingAddress, setShippingMethod } = useCheckout();
const { activeShippingAddress } = useSessionContext();
const { selectedPickupLocation } = useCart();



const selectedShippingMethodText = computed(() => {
	if (!selectedShippingMethod.value) {
		return {
			name: "Selecteer bezorgmethode",
			icon: "delivery"
		};
	}


	if(activeShippingAddress.value?.zipcode && invalidzipcodearea(activeShippingAddress.value?.zipcode)){
		setShippingMethod(SHIPPING_METHODS.pickup)
	}

	const name =
		selectedShippingMethod.value.technicalName === "pickup" && selectedPickupLocation.value?.name
			? selectedPickupLocation.value.name
			: selectedShippingMethod.value.name;

	const icon = selectedShippingMethod.value?.technicalName === "pickup" ? "store" : "delivery";

	return { name, icon };
});
</script>

<template>
	<span>
		<ClientOnly>
			<slot v-bind="{ shippingMethodSidebar }">
				<button class="flex items-center gap-2" @click="shippingMethodSidebar?.open()">
					<div class="grow overflow-hidden text-ellipsis whitespace-nowrap max-w-[110px]">
						{{ selectedShippingMethodText.name }}
					</div>
					<SvgIcon :name="selectedShippingMethodText.icon as Icon" />
				</button>
			</slot>
			<ShippingMethodSidebar ref="shippingMethodSidebar" />

			<template #fallback>
				<button class="flex items-center gap-2" @click="shippingMethodSidebar?.open()">
					<div class="grow overflow-hidden text-ellipsis whitespace-nowrap max-w-[110px]">
						Selecteer bezorgmethode
					</div>
					<SvgIcon name="store" />
				</button>
			</template>
		</ClientOnly>
	</span>
</template>
