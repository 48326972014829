export const extractDutchZipcode = (zipcode?: string): number | undefined => {
	if (!zipcode) {
		return;
	}

	// Remove spaces from the zipcode
	const sanitizedZipcode = zipcode?.replace(/\s/g, "");

	// Dutch zipcode pattern (4 digits followed by 2 letters)
	const dutchZipcodePattern = /^\d{4}[A-Za-z]{2}$/;

	// Check if it matches the Dutch zipcode pattern
	if (!dutchZipcodePattern.test(sanitizedZipcode)) {
		// Return the first 4 digits (the numeric part)
		return;
	}

	return parseInt(sanitizedZipcode.substring(0, 4));
};
