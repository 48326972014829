import { defineNuxtRouteMiddleware } from "nuxt/app";
import { setCookie } from "h3";

// Middleware for loggin in users when the context token is available in the URL.
// Used for order confirmation page after payment, when the user is not logged in
// in the browser (user switched browsers during payment).
export default defineNuxtRouteMiddleware(async (to) => {
	if (!import.meta.server) return;

	const { ssrContext } = useNuxtApp();
	const contextToken = to.query?.context as string;

	if (contextToken && ssrContext?.event) {
		setCookie(ssrContext.event, "sw-context-token", contextToken);
	}
});
