<script setup lang="ts">
import type { StoryblokBreadcrumbsStoryblok } from "~/types/storyblok";

const props = defineProps<{
	blok?: StoryblokBreadcrumbsStoryblok;
	current?: string;
}>();

const breadcrumbs = computed<string[]>(() => {
	const route = useRoute();
	const pathComponents = route.path.split("/");
	pathComponents.shift();
	const includeHomepage = props.blok?.include_home_crumb ?? true;

	const crumbs = pathComponents.map((path) => (path.charAt(0).toUpperCase() + path.slice(1)).replaceAll("-", " "));
	/** Whether or not the home crumb needs to be included. */
	if (includeHomepage) {
		crumbs.splice(0, 0, "Home");
	}
	/** Whether or not the last crumb needs to be hidden */
	if (props.blok?.hide_last_crumb) {
		crumbs.splice(0, crumbs.length - 1);
	}

	// Replace last crumb with current page name
	if (props.current) {
		crumbs[crumbs.length - 1] = props.current;
	}
	return crumbs && crumbs.length ? crumbs : [];
});
</script>

<template>
	<div
		v-editable="blok"
		:class="{
			'container mt-6': blok?.container,
			'hidden md:block': blok?.desktopOnly
		}"
	>
		<Breadcrumbs :crumbs="breadcrumbs" class="inline-flex" />
	</div>
</template>
